import React from "react";
import { 
  //FaCode, 
  FaMobile 
} from "react-icons/fa";
import { MdWeb } from "react-icons/md";
import ServiceItem from "./ServiceItem";
import PortfolioItem from "../Portfolio/PortfolioItem";
import image3 from "../../../images/portfolio/project-03.jpg";
import image4 from "../../../images/portfolio/project-04.png";

const portfolioData = [
  // {
  //   id: 1,
  //   image: image1,
  //   title: "CheckinMe",
  //   link: "https://checkinme.app",
  //   description: "is a digital solution for traditional Medium Enterprises used to manage the human resources and sales activity automatically.",
  // },
  // {
  //   id: 2,
  //   image: image2,
  //   title: "Papa Deliver",
  //   link: "https://papadeliver.co/",
  //   description: "is a mobile delivery service that allows people the convenience to book their deliveries using technology.",
  // },
  {
    id: 3,
    image: image3,
    title: "POS System",
    // link: "https://demo.krobkrong.me/",
    description: "is a small POS and stock management system. can access everywhere, anytime, any devices. and has multiple reports.",
  },
  {
    id: 4,
    image: image4,
    title: "Loan System",
    // link: "https://demo.krobkrong.me/",
    description: "is a digital platform that helps automate every stage of the loan lifecycle, from application to closing.",
  }
];

const serviceData = [
  // {
  //   id: 1,
  //   icon: <FaCode />,
  //   title: "Freelancer",
  //   // description:
  //   //   "Lorem ipsum dolor sit aconsect dipisicing elit, sed do eiusmod to incididunt uabore etdolore magna aliqua.",
  // },
  {
    id: 2,
    icon: <MdWeb />,
    title: "Web Development",
    // description:
    //   "Lorem ipsum dolor sit aconsect dipisicing elit, sed do eiusmod to incididunt uabore etdolore magna aliqua.",
  },
  {
    id: 3,
    icon: <FaMobile />,
    title: "Mobile Application",
    // description:
    //   "Lorem ipsum dolor sit aconsect dipisicing elit, sed do eiusmod to incididunt uabore etdolore magna aliqua.",
  },
  // {
  //   id: 4,
  //   icon: <FaBootstrap />,
  //   title: "Bootstrap Template",
  //   // description:
  //   //   "Lorem ipsum dolor sit aconsect dipisicing elit, sed do eiusmod to incididunt uabore etdolore magna aliqua.",
  // },
];

const Service = () => {
  return (
    <section className="pb-5">
      <div className="flex flex-wrap md:px-4">
        {portfolioData.map((portfolio, id) => (
          <PortfolioItem portfolio={portfolio} key={id} />
        ))}
        {serviceData.map((service, id) => (
          <ServiceItem service={service} key={id} />
        ))}
      </div>
    </section>
  );
};

export default Service;
