import React from "react";
import {
  FaFacebookF,
  FaLinkedinIn,
  FaTwitter,
  FaTelegram
} from "react-icons/fa";
import profile from "../../../images/profile.jpg";
import { useTranslation } from "react-i18next";
import {
  BrowserRouter as Router,
  NavLink
} from "react-router-dom";

const socials = [
  {
    id: 4,
    icon: <FaTelegram />,
    link: "https://t.me/Sokchea_van",
  },
  {
    id: 1,
    icon: <FaFacebookF />,
    link: "https://www.facebook.com/IZ7.O.O.I/",
  },
  {
    id: 2,
    icon: <FaLinkedinIn />,
    link: "https://www.linkedin.com/in/sokchea-van-4462221b8/",
  },
  {
    id: 3,
    icon: <FaTwitter />,
    link: "https://twitter.com/VanSokchea1",
  },
];

const Sidebar = () => {
  const { t, i18n } = useTranslation();

  const changeLanguage = () => {
    i18n.changeLanguage(i18n.language === 'en' ? 'km' : 'en');
  }

  return (
      <aside className="sticky top-0 bg-white md:mx-8 lg:mx-4 mb-8 p-6 shadow-md rounded-md -mt-40">
        <Router>
          <NavLink replace to={i18n.language === 'en' ? '/km' : '/'} onClick={changeLanguage} className="absolute top-0 right-0">
            {i18n.language === 'en' ? <span className="fi fi-kh text-lg"></span> : <span className="fi fi-gb text-lg"></span>}
          </NavLink>
        </Router>
        <div className="w-24 h-24 rounded-md overflow-hidden mx-auto mb-5">
          <img src={profile} alt="shafiqhammad" className="w-full" />
        </div>
        <div className="text-center">
          <h1 className="text-xl text-gray-800 font-bold mb-1">{t('Sokchea Van')}</h1>
          <p className="text-sm text-gray-400 mb-3">
            {t('IT Director at')}
            <a href="https://checkinme.app" target="_blank" rel="noreferrer" className="text-blue-500 pl-1">
              CheckinMe 
            </a>
          </p>
          {/* <a
            href="#0"
            className="inline-block mb-3 rounded bg-blue-500 text-center border-0 py-2 px-6 text-white leading-7 tracking-wide hover:bg-blue-800"
            download="Resume"
          >
            Download Resume
          </a> */}
          <ul className="flex flex-wrap justify-center">
            {socials.map((social, id) => (
              <SocialIcon social={social} key={id} />
            ))}
          </ul>
        </div>
        {/* <div className="text-start pt-4">
          <h3 className="text-md mb-2 uppercase font-medium text-gray-800">
            {t('About Me')}
          </h3>
          <p className="text-gray-400 text font-light leading-relaxed">
            {t("As an IT Director at a company, I oversee a broad spectrum of responsibilities, from managing teams to steering the development of databases, APIs, backend systems, and mobile apps. My recent initiatives involve transitioning from traditional VM servers to Platform as a Service (PaaS) solutions for enhanced scalability and efficiency. Leveraging platforms like Azure, I orchestrate the utilization of services such as Azure Key Vault, Blob Storage, and App Service to streamline our operations and bolster our infrastructure.")}
          </p>
        </div> */}
      </aside>
  );
};

export default Sidebar;

const SocialIcon = (props) => {
  const { icon, link } = props.social;
  return (
    <li className="m-2">
      <a
        href={link}
        target="_blank"
        rel="noreferrer"
        className="w-8 h-8 bg-blue-100 rounded text-blue-800 flex items-center justify-center hover:text-white hover:bg-blue-500"
      >
        {icon}
      </a>
    </li>
  );
};
