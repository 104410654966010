import React from "react";
import {
  BrowserRouter as Router,
  // NavLink,
  Route,
  Switch,
} from "react-router-dom";
import About from "../../pages/About/About";

// const navbarData = [
//   {
//     id: 1,
//     title: "About",
//     to: "/about",
//   },
//   {
//     id: 2,
//     title: "Services",
//     to: "/services",
//   },
//   {
//     id: 3,
//     title: "Works",
//     to: "/works",
//   },
//   {
//     id: 4,
//     title: "Blogs",
//     to: "/blogs",
//   },
//   {
//     id: 5,
//     title: "Contact",
//     to: "/contact",
//   },
// ];

const Navbar = () => {
  return (
    <Router>
      {/* <nav className="md:mx-8 mb-3 px-6 py-2 z-10 sticky top-0 bg-white shadow rounded">
        <ul className="flex flex-wrap">
          {navbarData.map((el, id) => (
            <LinkItem el={el} key={id} />
          ))}
        </ul>
      </nav> */}

      <Switch>
        <Route exact path="/km">
          <About language="km"/>
        </Route>
        {/* <Route path="/services">
          <Service />
        </Route>
        <Route path="/works">
          <Portfolio />
        </Route>
        <Route path="/blogs">
          <Blog />
        </Route>
        <Route path="/contact">
          <Contact />
        </Route>
        <Route path="/about">
          <About />
        </Route> */}
        <Route>
          <About/>
        </Route>
      </Switch>
    </Router>
  );
};

export default Navbar;

// const LinkItem = (props) => {
//   const { title, to } = props.el;
//   return (
//     <li className="m-3 lg:mx-5">
//       <NavLink
//         to={to}
//         activeClassName="text-blue-500"
//         className="text-gray-800 text-medium hover:text-blue-500"
//       >
//         {title}
//       </NavLink>
//     </li>
//   );
// };
