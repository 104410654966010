import React from "react";
import image1 from "../../../images/portfolio/project-01.png";
import image2 from "../../../images/portfolio/project-02.jpeg";
// import image3 from "../../../images/portfolio/project-03.jpg";
import PortfolioItem from "./PortfolioItem";

const portfolioData = [
  {
    id: 1,
    image: image1,
    title: "CheckinMe",
    link: "https://checkinme.app",
    description: "is a digital solution for traditional Medium Enterprises used to manage the human resources and sales activity automatically.",
  },
  {
    id: 2,
    image: image2,
    title: "Papa Deliver",
    link: "https://papadeliver.co/",
    description: "is a mobile delivery service that allows people the convenience to book their deliveries using technology.",
  },
  // {
  //   id: 3,
  //   image: image3,
  //   title: "POS System",
  //   // link: "https://demo.krobkrong.me/",
  //   description: "is a small POS and stock management system. can access everywhere, anytime, any devices. and has multiple reports.",
  // }
];

const Portfolio = () => {
  return (
    <section className="pb-10">
      <div className="flex flex-wrap md:px-4">
        {portfolioData.map((portfolio, id) => (
          <PortfolioItem portfolio={portfolio} key={id} />
        ))}
      </div>
    </section>
  );
};

export default Portfolio;
